/* src/styles/variables.css */
:root {
  /* Primary Colors */
  --primary-main: #4A90E2; /* Calm blue for primary actions */
  --primary-light: #B3D4FC;
  --primary-dark: #1C3A72;

  /* Background Colors */
  --background-light: #FFFFFF; /* Light background for overall app */
  --background-medium: #F5F5F5; /* For cards and lighter surfaces */
  --background-dark: #1E1E1E; /* For dark mode or dark-themed cards */
  --surface: #FFFFFF; /* Default card background color */

  /* Text Colors */
  --text-primary: #212121; /* Dark text for better contrast */
  --text-secondary: #757575; /* Lighter for secondary info */
  --text-muted: #9E9E9E; /* Disabled or muted text */

  /* Shadows */
  --shadow-light: rgba(0, 0, 0, 0.05);
  --shadow-medium: rgba(0, 0, 0, 0.1);
  --shadow-dark: rgba(0, 0, 0, 0.3);
}
